export const API_Endpoint = {

  // Login
  admin_user_sign_in: '/admin/user/sign-in',

  // Authenticated User
  authenticated_user: '/admin/authenticated-user',

  // My Account
  my_account: '/admin/profile',

  // Roles and Permissions
  role: '/admin/role',
  role_and_permission: '/admin/role-and-permission',


  // Roles and Permissions
  subscription_plan: '/admin/subscription/plan',

  // Admin User
  admin_user: '/admin/user',

  // Resume End User
  resume_end_user: '/admin/resume-end-user',

  // blog
  blog: '/admin/blogs',

  // Common Services
  common_services: {
    role_list: '/admin/common/services/role/list',
    permission_list: '/admin/common/services/permission/list',
    subscription_plan_list: '/admin/common/services/subscription-plan/list',
  },
}

export const FE_route = {
  login: '/auth/login',
  dashboard: '/pages/resume/dashboard',
  role_permission_list: '/pages/resume/roles-permissions',
  admin_user_list: '/pages/resume/admin-users',
  subscription_plan_list: '/pages/resume/subscription-management',
  my_account: '/pages/resume/my-account',
  blogs: '/pages/numitrex/blogs',
  blogs_edit: (id:any) => `/pages/numitrex/blogs/edit/${id}`,
}
