import { Component, OnDestroy, AfterViewInit, Output, EventEmitter, ElementRef, Input } from '@angular/core';
import { LocationStrategy } from '@angular/common';
import { TinyMceService } from '../../../helper/services/tiny-mce/tiny-mce.service';

@Component({
  selector: 'ngx-tiny-mce',
  template: '',
})
export class TinyMCEComponent implements OnDestroy, AfterViewInit {

  @Output() editorKeyup = new EventEmitter<any>();
  @Input() introduction: string | null = null;
  @Input() content: string | null = null;
  @Input() conclusion: string | null = null;
  @Input() id: string = ''; // Unique ID for the editor instance

  constructor(
    private host: ElementRef,
    private locationStrategy: LocationStrategy,
    private tinyMceService: TinyMceService
  ) { }

  ngAfterViewInit() {
    this.tinyMceService.initEditor(this.id, this.host.nativeElement, this.editorKeyup);
  }

  ngOnDestroy() {
    // Clean up the editor when the component is destroyed
    tinymce.remove();
  }
}
