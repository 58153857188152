export const FormContentLimit = {
  first_name: {
    min: 3,
    max: 30,
  },
  last_name: {
    min: 3,
    max: 30,
  },
  meta_title: {
    min: 3,
    max: 255,
  },
  meta_description: {
    min: 3,
    max: 255,
  },
  mobile: {
    max: 16,
  },
  password: {
    min: 6,
    max: 30,
  },
  role_name: {
    min: 3,
    max: 30,
  },
  role_description: {
    min: 3,
    max: 100,
  },
  subscription_name: {
    min: 3,
    max: 30,
  },
  subscription_description: {
    min: 3,
    max: 300,
  }
}
