import { LocationStrategy } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TinyMceService {

  private editors: Map<string, any> = new Map<string, any>();

  constructor(
    private locationStrategy: LocationStrategy,
  ) { }

  initEditor(id: string, hostElement: HTMLElement, editorKeyupEmitter: any) {
    const editor = tinymce.init({
      target: hostElement,

      plugins: ['link', 'paste', 'image', 'imagetools', 'preview', 'wordcount', 'colorpicker'],
      // plugins: ['link', 'paste', 'table', 'image', 'imagetools', 'preview', 'wordcount', 'colorpicker'],


      paste_as_text: true,
      skin_url: `${this.locationStrategy.getBaseHref()}assets/skins/lightgray`, // Update the path according to your project structure
      // skin_url: 'assets/skins/lightgray', // Update the path according to your project structure
      setup: (instance: any) => {
        instance.on('keyup', () => {
          editorKeyupEmitter.emit(instance.getContent());
        });
      },
      height: '101',
    });

    this.editors.set(id, editor);
  }

  removeEditor(id: string) {
    const editor = this.editors.get(id);
    if (editor) {
      tinymce.remove(editor);
      this.editors.delete(id);
    }
  }

  setContent(id: string, content: string) {
    tinymce.get(id).setContent(content);
  }

  getContent(id: string): string {
    const editor = this.editors.get(id);
    return editor ? editor.getContent() : '';
  }
}
