import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { API_Endpoint, FE_route } from '../api_endpoints';
import { AdminUserInterface } from '../interface/admin_user_interface';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  api_url: string;
  login_user_data: any = new BehaviorSubject({});
  private permission_list: any = new BehaviorSubject([]);
  menu_items: any = new BehaviorSubject([]);

  constructor(
    private http: HttpClient,
    private router: Router
  ) {
    this.api_url = environment.api_url;
  }

  isUserLogin() {
    return this.getToken() ? true : false;
  }

  getToken() {
    return localStorage.getItem('auth_token');
  }

  setPermissionList(data: any) {
    data = data.map((item: any) => item.slug);
    this.permission_list.next(data);
  }

  getPermissionList() {
    return this.permission_list.asObservable();
  }

  updateLoginUserData(data: any) {
    this.login_user_data.next(data);
  }

  getMyAccountData() {
    return this.http.get(`${this.api_url}${API_Endpoint.my_account}`);
  }

  updateMyAccountData(data: any) {
    return this.http.patch(`${this.api_url}${API_Endpoint.my_account}`, data);
  }

  adminUserlogin(data: AdminUserInterface) {
    return this.http.post(`${this.api_url}${API_Endpoint.admin_user_sign_in}`, data);
  }

  getPermissionSlug = async () => {
    const data: any = await this.http.get(`${this.api_url}${API_Endpoint.authenticated_user}`).toPromise();
    this.updateLoginUserData(data?.data);
    this.setMenuItems(data?.data?.permission_list);
    return data?.data?.permission_list.map((item: any) => item.slug);
  }

  setMenuItems(permission_list) {
    let permission_array = permission_list;
    let menu = [];

    let groupedList = permission_array.reduce((acc, cur) => {
      if (!acc[cur.name]) {
        acc[cur.name] = [];
      }
      acc[cur.name].push(cur);
      return acc;
    }, {});

    permission_array = Object.keys(groupedList).map((key) => {
      return {
        name: key,
        data: groupedList[key]
      }
    })

    permission_array.map((item: any) => {

      if (item.data.some((ele: any) => ele.slug.includes("dashboard"))) {
        menu.push(
          {
            title: item?.name,
            icon: 'home-outline',
            link: '/pages/resume/dashboard',
            home: true,
          },
        )
        return;
      }

      if (item.data.some((ele: any) => ele.slug.includes("user_management"))) {
        menu.push(
          {
            title: item?.name,
            icon: 'people-outline',
            children: [
              {
                title: 'Admin Users',
                link: '/pages/resume/admin-users',
              },
              {
                title: 'End Users',
                link: '/pages/resume/users',
              },
            ],
          },
        )
        return;
      }

      if (item.data.some((ele: any) => ele.slug.includes("roles_and_permission"))) {
        menu.push(
          {
            title: item?.name,
            icon: 'award-outline',
            link: '/pages/resume/roles-permissions',
            // home: true,
          },
        )
        return;
      }

      if (item.data.some((ele: any) => ele.slug.includes("subscription_management"))) {
        menu.push(
          {
            title: item?.name,
            icon: 'person-done-outline',
            link: '/pages/resume/subscription-management',
            // home: true,
          },
        )
        return;
      }
    })

    menu.push(
      {
        title: 'Settings',
        icon: 'settings-2-outline',
        children: [
          {
            title: 'Blogs',
            link: '/pages/numitrex/blogs',
          },
          {
            title: 'Privacy Policy',
            link: '/pages/numitrex/privacy-policy',
          },
        ],
      },
      {
        title: 'My Account',
        icon: 'person-outline',
        link: '/pages/resume/my-account',
        // home: true,
      },
      {
        title: 'Logout',
        icon: 'power-outline',
        skipLocationChange: true,
        link: '/',
        // home: true,
      },
    )

    this.menu_items.next(menu);
  }

  logout() {
    localStorage.clear();
    this.router.navigate([FE_route.login]);
  }
}
